<template>
  <div class="flex column items-center">
    <q-btn round to="/student">
      <div class="card-user">
        <div class="avatar-border">
          <q-circular-progress
            :value="level.progress"
            style="color: #35ddc7"
            :thickness="0.15"
            track-color="grey-3"
            class="progress-user"
          />
          <q-icon
            class="avatar"
            v-if="image == ''"
            name="account_circle"
            size="90px"
          />
          <q-img
            class="avatar"
            v-else
            :src="url_aws_bucket + image"
            spinner-color="white"
          >
            <template v-slot:error>
              <q-icon
                class="avatar-not-found"
                name="account_circle"
                size="90px"
              />
            </template>
          </q-img>
        </div>
        <div class="q-pa-sm badge-level">
          <levelOneIcon
            v-if="level.levelNumber == 'I'"
            class="icon-level"
            :level="level.className"
          />
          <levelTwoIcon
            v-else-if="level.levelNumber == 'II'"
            class="icon-level"
            :level="level.className"
          />
          <levelThreeIcon
            v-else-if="level.levelNumber == 'III'"
            class="icon-level"
            :level="level.className"
          />
        </div>
      </div>
    </q-btn>
    <label class="user-name q-mt-sm">{{ name }}</label>
    <label class="user-type q-mt-xs">{{ typeUser }}</label>
    <label class="user-type q-mt-xs">{{ company }}</label>
    <div class="flex justify-center items-end q-mt-sm">
      <!-- <q-img
        width="16px"
        height="16px"
        :src="coinsImg"
        spinner-color="white"
      /> -->
      <coinsImg color="white" />

      <router-link to="/student" class="user-coins q-ml-sm"
        >Carteira</router-link
      >
    </div>
    <router-link to="/store" class="flex justify-center items-baseline store">
      <q-img class="q-mr-sm store-icon" :src="storeImg" spinner-color="white" />

      <label class="store">Loja</label>
    </router-link>
    <div class="fixed on-right">
      <q-img
        class="q-ml-xl cursor-pointer"
        :src="url_aws_bucket + moodUser"
        spinner-color="primary"
        spinner-size="82px"
        style="width: 40px; height: 40px; border-radius: 50%"
        @click="handleDialog"
        v-if="moodUser"
      >
        <q-tooltip anchor="center right" self="center left" :offset="[10, 10]"
          >Atualizar humor</q-tooltip
        >
      </q-img>
      <q-btn
        v-else
        color="default-pink"
        icon="add_reaction"
        class="q-ml-xl"
        round
        @click="handleDialog"
        style="width: 40px; height: 40px; border-radius: 50%"
      >
        <q-tooltip anchor="center right" self="center left" :offset="[10, 10]"
          >Adicionar humor</q-tooltip
        >
      </q-btn>
    </div>
    <DialogSetMood
      :confirm="visibilityDialog"
      v-if="visibilityDialog"
      @closeDialog="() => (visibilityDialog = false)"
    />
  </div>
</template>

<script>
//----COMPONENTS
import levelOneIcon from "@/components/shared/levelsIcons/LevelOne.vue";
import levelTwoIcon from "@/components/shared/levelsIcons/LevelTwo.vue";
import levelThreeIcon from "@/components/shared/levelsIcons/LevelThree.vue";
import coinsImg from "@/assets/icons/custom/Coins.vue";
import storeImg from "@/assets/icons/store.svg";
import DialogSetMood from "../../profile/dialog/DialogSetMood.vue";

//----SERVICES
import UserServices from "@/services/UserServices";

//----UTILS
import { ENV } from "@/utils/env";

//----VUEJS
import {ref, onMounted, computed, watch, watchEffect, watchPostEffect} from "vue";
import {useStore} from "vuex";
import UpdateXpUser from "@/utils/UpdateXpUser";

export default {
  name: "CardPerson",
  components: {
    levelOneIcon,
    levelTwoIcon,
    levelThreeIcon,
    coinsImg,
    DialogSetMood,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    typeUser: String,
    name: String,
    company: String,
    image: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    //----CONSTANTS
    const UserService = new UserServices();
    const url_aws_bucket = ENV.URL_AWS_BUCKET;
    const moodUser = ref();

    const store = useStore();

    let coins = ref(0);
    let level = ref({
      className: "",
      levelNumber: "",
      progress: 0
    });
    const visibilityDialog = ref(false);

    async function _getUserIndicator() {

      const userdata = store.state.userdata;

      const response = await UpdateXpUser()

      userdata.progress = response.progress
      userdata.class_name = response.class_name
      userdata.leve_name =  response.level_name;

      store.commit("setUserData", userdata);

      if (response.class_name != null) {
        level.value.className = response.class_name;
        level.value.levelNumber = response.level_name;
        level.value.progress = response.progress
      }
    }

    function handleDialog() {
      visibilityDialog.value = !visibilityDialog.value;
    }

    async function getMood() {
      await UserService.getUserEmoji()
        .then((response) => {
          moodUser.value = response.data[0]?.emoji_path;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    onMounted(() => {
      _getUserIndicator();
      getMood();
    });

    watchEffect(() => {
      if (store.state.userdata.hasOwnProperty("class_name")){
        level.value.className = store.state.userdata.class_name;
      }
      if (store.state.userdata.hasOwnProperty("level_name")){
        level.value.levelNumber = store.state.userdata.level_name;
      }
      if (store.state.userdata.hasOwnProperty("progress")){
        level.value.progress = store.state.userdata.progress;
      }
    })

    return {
      url_aws_bucket,
      moodUser,
      coins,
      level,
      visibilityDialog,
      //coinsImg,
      storeImg,
      handleDialog,
    };
  },
};
</script>

<style lang="scss" scoped>
.text-profile {
  font-style: normal !important;
  text-align: center !important;
  color: #ffffff !important;

  .text-title {
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 22px !important;
  }

  .text-subtitle {
    font-weight: 300 !important;
    font-size: 14px !important;
    line-height: 17px !important;
  }
}

@media (max-width: 1040px) {
  .text-profile {
    display: none;
  }
}

.card-user {
  /* width: 75%; */
  //background-color: #ffffff;
  border-radius: 50%;
  padding: 0.5%;
  position: relative;

  .btn-user {
    font-size: 60%;
    position: absolute;
    right: -7.5%;
    bottom: 1.5%;
  }

  .badge-user {
    width: 28px;
    height: 28px;
    position: absolute;
    left: 5%;
    bottom: -10.5%;

    .badge-value {
      position: absolute;
      left: 27%;
      top: 22%;

      p {
        margin: 0;
        font-size: 0.9rem;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        color: #ffffff;
      }
    }
  }
  .badge-level {
    height: 40px;
    width: 40px;
    position: absolute;
    right: -1.5%;
    bottom: -0.5%;
    background: #fff;
    border-radius: 28px;

    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
      width: 45px;
    }
  }

  .avatar-border {
    overflow: hidden;
    height: 70px;
    width: 70px;
    border-radius: 93px;
    position: relative;
  }

  .progress-user {
    font-size: 500%;
  }

  .avatar {
    height: 80%;
    max-width: 80%;
    border-radius: 100%;
    position: absolute;
    left: 10%;
    top: 9.5%;
  }

  .avatar-not-found {
    // height: 80%;
    // max-width: 80%;
    // border-radius: 100%;
    // position: absolute;
    // left: 10%;
    // top: 10%;
  }

  @media (min-width: 1040px) {
    padding: 5%;

    .avatar-border {
      overflow: hidden;
      height: 110px;
      width: 110px;
      border-radius: 93px;
      position: relative;
    }

    .progress-user {
      font-size: 780%;
    }

    .btn-user {
      font-size: 80%;
      position: absolute;
      right: 3.5%;
      bottom: 2.5%;
    }

    .badge-user {
      width: 28px;
      height: 28px;
      position: absolute;
      left: 15%;
      bottom: 10.5%;

      .badge-value {
        position: absolute;
        left: 27%;
        top: 22%;

        p {
          margin: 0;
          font-size: 0.9rem;
          //font-family: "Montserrat", sans-serif;
          font-weight: 700;
          color: #ffffff;
        }
      }
    }
  }
}

.user-name {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}

.user-type {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}

.user-coins {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 1px;
  color: #fff;
  text-decoration: none;
}

.store {
  margin-top: 12px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: #ffffff;
  text-decoration: none;
  cursor: pointer;

  .store-icon {
    width: 20px;
    height: 16px;
  }
}
</style>
