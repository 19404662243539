import defaultTheme from "@/utils/styles/themes/defaultTheme";
import dasaTheme from "@/utils/styles/themes/dasaTheme";
import liveloTheme from "@/utils/styles/themes/livelo";
import cnpTheme from "@/utils/styles/themes/cnpTheme";
import comGasTheme from "@/utils/styles/themes/comgasTheme";
import bancoMercantilTheme from "@/utils/styles/themes/bancoMercantilTheme.js";

export default class ThemeApp {
  setThemeById(id) {
    switch (id) {
      case 14:
        dasaTheme();
        break
      case 16:
        liveloTheme();
        break
      case 18:
        cnpTheme();
        break
      case 19:
        comGasTheme();
        break
      case 21:
        bancoMercantilTheme();
        break
      default:
        defaultTheme();
        break;
    }
  }
}
